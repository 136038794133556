import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Row, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import CustomModal from "components/CustomModal";
import UploadFiles from "components/UploadFiles";
import { PAYMENT_METHOD } from "config/constants";
import { useApprovePayment, useConfirmPayment } from "hooks/order";
import { useUploadDocument } from "hooks/upload-document";
import { t } from "i18next";
import { useState } from "react";
import { formatCurrency, getFile, notify } from "utils/helperFuncs";
import "./index.scss";

export const CustomModalApprovePayment = ({ orderIDs, orderDetail, resetCheckedList }) => {
  const [visible, setVisible] = useState(false);
  const { handleApprovePayment } = useApprovePayment();
  const { handleConfirmPayment } = useConfirmPayment();

  const [form] = useForm();
  const { handleUpload } = useUploadDocument();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    await form.validateFields().then(async () => {
      setLoading(true);
      try {
        const formValues = form.getFieldsValue();
        const { imageURLs } = formValues;
        const originFiles = imageURLs?.map((item) => item.originFileObj);
        await handleUpload({ files: originFiles }).then(async (res) => {
          const imageURLs = res?.map((item) => item?.url);

          if (orderDetail?.paymentMethod === PAYMENT_METHOD.Cash) {
            await handleApprovePayment({ orderIDs: orderIDs && orderIDs[0], paymentProofs: imageURLs })
              .then(() => {
                notify.success({
                  message: t("order.orderDetail.paymentApprovalSuccess"),
                });
                setVisible(false);
                resetCheckedList && resetCheckedList();
              })
              .catch((error) => {
                notify.error({
                  message: t("order.orderDetail.paymentApprovalError"),
                  description: error.message,
                });
              });
          } else {
            await handleConfirmPayment({ orderIDs: orderIDs })
              .then(() => {
                notify.success({
                  message: t("order.orderDetail.paymentApprovalSuccess"),
                });
                setVisible(false);
                resetCheckedList && resetCheckedList();
              })
              .catch((error) => {
                notify.error({
                  message: t("order.orderDetail.paymentApprovalError"),
                  description: error.message,
                });
              });
          }
        });
      } catch (err) {
        notify.error({
          message: err.message,
        });
      } finally {
        setLoading(false);
      }
    });
  };

  const handleOpenModal = () => {
    if (orderIDs?.length > 0) {
      setVisible(true);
    } else {
      notify.warning({
        message: t("order.orderList.selectOrderValidate"),
      });
    }
  };
  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <div className="modal-custom-approval-payment-order">
      <CustomModal
        footer={false}
        centered={true}
        customComponent={
          <Button type="primary" loading={loading} style={{ color: "#fff" }}>
            {t("order.orderDetail.approvedPayment")}
          </Button>
        }
        onOke={handleSubmit}
        onCancel={handleCloseModal}
        onOpen={handleOpenModal}
        visible={visible}
        width="35%"
        buttonLoading={loading}
      >
        <Form form={form} initialValues={{ imageURLs: [] }}>
          <Row gutter={[16, 8]}>
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
              <ExclamationCircleFilled style={{ fontSize: "48px", color: "#ff7f00" }} />
            </Col>

            {orderDetail?.paymentMethod === PAYMENT_METHOD.Cash ? (
              <div className="render-content-modal-cash-approval-payment">
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                  <Typography.Text strong>{t("order.orderDetail.confirm-order-success")}</Typography.Text>
                </div>
                <div>
                  <Typography.Text>{t("order.orderDetail.please-check-money")}</Typography.Text>
                </div>
                <div className="list-checkbox">
                  <Form.Item
                    name="checkbox"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error(t("order.orderDetail.reject-checkbox"))),
                      },
                    ]}
                  >
                    <Checkbox>
                      <Typography.Text>
                        {t("order.orderDetail.order")}
                        <Typography.Link strong>&nbsp;{orderDetail?.number}&nbsp;</Typography.Link>
                        {t("order.orderDetail.value")}
                        <Typography.Link strong>
                          &nbsp;{formatCurrency(orderDetail?.customerTotal)}&nbsp;
                        </Typography.Link>
                        {t("order.orderDetail.seller-by")}
                        <Typography.Link strong>
                          &nbsp;{orderDetail?.receiverName || orderDetail?.receiverContactName}&nbsp;
                        </Typography.Link>
                        {t("order.orderDetail.success-payment")}
                      </Typography.Text>
                    </Checkbox>
                  </Form.Item>
                </div>
                <div>
                  <Typography.Text>{t("order.orderDetail.upload-file")}</Typography.Text>
                </div>
                <div>
                  <Form.Item
                    labelCol={{ span: 24, offset: 0 }}
                    name={"imageURLs"}
                    getValueFromEvent={getFile}
                    valuePropName={"fileList"}
                    extra={<Typography.Text italic>{t("common.noteAttack")}</Typography.Text>}
                  >
                    <UploadFiles />
                  </Form.Item>
                </div>
              </div>
            ) : (
              <div style={{ margin: "12px 50px", width: "100%", textAlign: "center" }}>
                <Typography.Text>{t("order.orderList.confirmPaymentWarning")}</Typography.Text>
              </div>
            )}
          </Row>
        </Form>
      </CustomModal>
    </div>
  );
};
